import {
  pathOr,
  compose,
  map,
  reduce,
  reduceBy,
  mergeAll,
  concat,
  path,
  values,
  mapObjIndexed,
  head,
  intersection,
  prop,
  uniq,
} from "ramda";
import { gql } from "graphql-tag";

import getShopifyGlobalId from "./get-shopify-global-id";
import { getProduct, getVariant } from "./normalize-product";
import hasSingleSize from "./has-single-size";
import groupByProp from "./group-by-prop";

const IMAGE_REFERENCES = gql`
  fragment ImageReferences on Metafield {
    references(first: 10) {
      nodes {
        ... on MediaImage {
          image {
            id
            altText
            src: url
            w_1500_h_1125: url(transform: { maxWidth: 1500, maxHeight: 1125 })
            w_1024_h_767: url(
              transform: {
                maxWidth: 1024
                maxHeight: 767
                preferredContentType: PNG
              }
            )
            w_1024_h_767_webp: url(
              transform: {
                maxWidth: 1024
                maxHeight: 767
                preferredContentType: WEBP
              }
            )
            w_60_h_60: url(
              transform: {
                maxWidth: 60
                maxHeight: 60
                preferredContentType: PNG
                crop: TOP
              }
            )
            w_60_h_60_webp: url(
              transform: {
                maxWidth: 60
                maxHeight: 60
                preferredContentType: WEBP
                crop: TOP
              }
            )
          }
        }
      }
    }
  }
`;
const BASIC_PRODUCT_VARIANT_DETAIL = gql`
  fragment BasicProductVariantDetail on ProductVariant {
    sku
    id
    product {
      id
      handle
    }
    title
    availableForSale
    compareAtPrice {
      amount
    }
    price {
      amount
    }
    selectedOptions {
      name
      value
    }
    quickship: metafield(namespace: "snooze", key: "quickship") {
      key
      value
      type
    }
    images: metafield(namespace: "snooze", key: "images") {
      ...ImageReferences
    }
  }
  ${IMAGE_REFERENCES}
`;

const PRODUCT_VARIANT_DETAIL = gql`
  fragment ProductVariantDetail on ProductVariant {
    weight
    weightUnit
    metafields(
      identifiers: [
        { namespace: "snooze", key: "width" }
        { namespace: "snooze", key: "height" }
        { namespace: "snooze", key: "depth" }
        { namespace: "snooze", key: "warranty" }
        { namespace: "snooze", key: "delivery_truck" }
        { namespace: "snooze", key: "mattress_comfort_feel" }
        { namespace: "snooze", key: "mattress_comfort_feel_left" }
        { namespace: "snooze", key: "mattress_comfort_feel_right" }
        { namespace: "snooze", key: "mattress_coefficient" }
        { namespace: "snooze", key: "mattress_left_coefficient" }
        { namespace: "snooze", key: "mattress_right_coefficient" }
        # { namespace: "snooze", key: "cloud_rating" }
        { namespace: "snooze", key: "lead_time_text" }
        { namespace: "snooze", key: "lead_time_days_min" }
        { namespace: "snooze", key: "color_hex" }
        { namespace: "snooze", key: "quickship" }
        { namespace: "snooze", key: "storage_space" }
        { namespace: "snooze", key: "variant_weight" }
        { namespace: "snooze", key: "bonus_variant_id" }
        { namespace: "snooze", key: "external_video" }
      ]
    ) {
      key
      value
      type
    }
  }
`;

const BASIC_PRODUCT_DETAIL = gql`
  fragment BasicProductDetail on Product {
    id
    title
    handle
    productType
    tags
    vendor
    metafields(
      identifiers: [
        { namespace: "snooze", key: "brand_list" }
        { namespace: "snooze", key: "three_d_url" }
        { namespace: "snooze", key: "product_campaign_badge_color" }
        { namespace: "snooze", key: "product_campaign_badge_title" }
        { namespace: "snooze", key: "product_category" }
        { namespace: "snooze", key: "mattress_protector" }
      ]
    ) {
      key
      value
      type
    }
  }
`;

const PRODUCT_DETAIL = gql`
  fragment ProductDetail on Product {
    seo {
      description
      title
    }
    description
    descriptionHtml
    priceRange {
      maxVariantPrice {
        amount
      }
      minVariantPrice {
        amount
      }
    }
    collections(first: 20) {
      nodes {
        id
        handle
        title
      }
    }
    metafields(
      identifiers: [
        { namespace: "snooze", key: "brand_list" }
        { namespace: "snooze", key: "three_d_url" }
        { namespace: "snooze", key: "product_campaign_badge_color" }
        { namespace: "snooze", key: "product_campaign_badge_title" }
        { namespace: "snooze", key: "product_category" }
        { namespace: "snooze", key: "mattress_protector" }
      ]
    ) {
      key
      value
      type
    }
  }
`;

const PRODUCT_DETAIL_BY_HANDLE = gql`
  query ProductDetailByHandle(
    $handle: String!
    $variantId: ID!
    $isVariantSelected: Boolean!
    $isQuickviewProduct: Boolean!
  ) {
    product(handle: $handle) {
      ...BasicProductDetail
      ...ProductDetail @skip(if: $isQuickviewProduct)
      firstVariant: variants(first: 1) @skip(if: $isVariantSelected) {
        nodes {
          ...BasicProductVariantDetail
          ...ProductVariantDetail @skip(if: $isQuickviewProduct)
        }
      }
    }
    node(id: $variantId) @include(if: $isVariantSelected) {
      ...BasicProductVariantDetail
      ...ProductVariantDetail @skip(if: $isQuickviewProduct)
    }
  }
  ${BASIC_PRODUCT_DETAIL}
  ${PRODUCT_DETAIL}
  ${BASIC_PRODUCT_VARIANT_DETAIL}
  ${PRODUCT_VARIANT_DETAIL}
`;

const PRODUCT_GROUP_DETAIL = gql`
  fragment ProductGroupDetail on Product {
    id
    title
    handle
    options {
      name
      values
    }
    variants(first: 100) {
      nodes {
        id
        selectedOptions {
          name
          value
        }
        price {
          amount
        }
        metafields(identifiers: [{ namespace: "snooze", key: "color_hex" }]) {
          value
          key
          type
        }
      }
    }
  }
`;

const PRODUCTS_BY_TAGS = gql`
  query ProductsByTitle($query: String!) {
    products(query: $query, first: 20) {
      nodes {
        ...ProductGroupDetail
      }
    }
  }
  ${PRODUCT_GROUP_DETAIL}
`;

const PRODUCT_BY_ID = gql`
  query ProductById($productId: ID!) {
    node(id: $productId) {
      ... on Product {
        ...ProductGroupDetail
      }
    }
  }
  ${PRODUCT_GROUP_DETAIL}
`;

const VARIANT_BY_ID = gql`
  query VariantById($id: ID!, $isQuickviewProduct: Boolean!) {
    node(id: $id) {
      ...BasicProductVariantDetail
      ...ProductVariantDetail @skip(if: $isQuickviewProduct)
    }
  }
  ${BASIC_PRODUCT_VARIANT_DETAIL}
  ${PRODUCT_VARIANT_DETAIL}
`;

// create a map of variantIds and option values
// optionName-OptionValue: [variantIds...]
// Comfort Feel Left- Plush : [34762584293420, 34762556145708]
const createVariantOptionsMap = (options, variants, withOptionName = false) => {
  const groupByOption = optionName =>
    compose(
      concat(withOptionName ? `${optionName}-` : ""),
      pathOr("", ["selectedOptions", optionName, "value"])
    );

  const byOption = reduceBy((acc, { id }) => acc.concat(id), []);
  const groupFuncs = map(
    compose(byOption, groupByOption, path(["name"])),
    options
  );
  return mergeAll(map(f => f(variants), groupFuncs));
};

export const getTilesInfo = (options, variants) => {
  const variantOptionsMap = createVariantOptionsMap(options, variants, true);

  return selectedOptionsObj => {
    const selectedOptions = values(
      mapObjIndexed((value, key) => `${key}-${value}`, selectedOptionsObj)
    );
    const selectedOptionsValues = values(selectedOptionsObj);

    return options.map((_, optionIndex) => {
      const tiles = options[optionIndex].values.map(optionValue => {
        for (
          let accuracy = options.length;
          accuracy > optionIndex;
          accuracy--
        ) {
          // Formulate an array of target options with given accuracy
          let targetOptions = selectedOptions.slice(0, accuracy);
          targetOptions[
            optionIndex
          ] = `${options[optionIndex].name}-${optionValue}`;

          // Attempt to find variant with target options
          const target = head(
            reduce(
              (acc, v) => intersection(acc, variantOptionsMap[v] || []),
              variantOptionsMap[targetOptions[0]] || [],
              targetOptions
            )
          );

          if (target) {
            // Target found, store accuracy and break
            return {
              tileLabel: optionValue,
              targetAccuracy: accuracy,
              targetVariantId: target,
            };
          }
        }

        // if not returned from the loop then return this value
        return {
          tileLabel: optionValue,
          targetAccuracy: 0,
          targetVariantId: null,
        };
      });
      return {
        label: options[optionIndex].name,
        tiles,
        selectedTile: selectedOptionsValues[optionIndex],
      };
    });
  };
};

export const getSelectedVariantId = variantOptionsMap => selectedOptions => {
  const allOptions = values(selectedOptions);
  const allVariantIds = compose(
    xs => reduce((acc, v) => intersection(acc, v), xs[0], xs),
    map(option => pathOr([], [option], variantOptionsMap))
  );

  return path([0], allVariantIds(allOptions));
};

export const getVariantsData = async (
  variantId,
  $getShopifyData,
  isQuickviewProduct = false
) => {
  const { data } = await $getShopifyData({
    query: VARIANT_BY_ID,
    variables: { id: getShopifyGlobalId(variantId), isQuickviewProduct },
  });

  return getVariant(data?.node);
};

export default async function (
  handle,
  variantId,
  $getShopifyData,
  isQuickviewProduct = false
) {
  const isVariantSelected = Boolean(variantId);
  const globalVariantId = getShopifyGlobalId(variantId);

  const { data: productData } = await $getShopifyData({
    query: PRODUCT_DETAIL_BY_HANDLE,
    variables: {
      handle,
      variantId: globalVariantId,
      isVariantSelected,
      isQuickviewProduct,
    },
  });

  const response = productData?.product;
  if (!response) {
    return null;
  }

  const selectedVariant = isVariantSelected
    ? productData?.node
    : response?.firstVariant?.nodes?.[0];

  if (!selectedVariant) {
    return null;
  }

  // #region products processing
  const product = getProduct(response);
  const mvTag = product.tags.find(tag => tag.includes("mv:"));
  const opmf = product.tags.find(tag => tag.includes("opmf:"));

  let products = [];
  if (mvTag || opmf) {
    const { data } = await $getShopifyData({
      query: PRODUCTS_BY_TAGS,
      variables: { query: mvTag ? `tag:'${mvTag}'` : `tag:'${opmf}'` },
    });
    products = data?.products?.nodes ?? [];
  } else {
    const { data } = await $getShopifyData({
      query: PRODUCT_BY_ID,
      variables: { productId: response.id },
    });
    products = data.node ? [data.node] : [];
  }

  const getOptions = compose(
    reduce((acc, [name, values]) => acc.concat({ name, values }), []),
    x => Object.entries(x),
    reduceBy((acc, { values }) => uniq(acc.concat(values)), [], prop("name"))
  );
  const options = getOptions(products.flatMap(product => product.options));

  const variants = products
    .flatMap(product => product.variants.nodes)
    .map(getVariant);
  // #endregion

  return {
    ...product,
    options,
    selectedVariant: getVariant(selectedVariant),
    variants,
    campaign: {
      saleColor: product.product_campaign_badge_color,
      saleTitle: product.product_campaign_badge_title,
    },
  };
}

export function getSeoData({
  title: defaultTitle,
  brand_list: brandList,
  tags,
  seo,
}) {
  const createMetaTitle = () => {
    const metaTitle = seo?.title ?? defaultTitle;
    let title = "";
    if (brandList && !metaTitle.startsWith(brandList)) {
      title += `${brandList} `;
    }
    title += metaTitle;
    if (tags.includes("afterpay-bedroom-furniture")) {
      title += " | Afterpay Available";
    }
    title += " | Snooze";
    return title;
  };

  const metaDescription = seo?.description ?? "";

  return { metaTitle: createMetaTitle(), metaDescription };
}

const getComfortFeel = option => {
  const comfortFeels = [
    "Comfort Feel",
    "Comfort Feel Left",
    "Comfort Feel - Side One",
  ];

  return comfortFeels.reduce((result, feel) => {
    if (result) {
      return result;
    }
    return pathOr("", [feel, "value"], option);
  }, "");
};

export const getVariantTitle = ({ variant, isMultiVariant }) => {
  if (variant.title === "Default Title") {
    return "";
  }

  const { selectedOptions } = variant;
  const options = Array.isArray(selectedOptions)
    ? groupByProp("name")(selectedOptions)
    : selectedOptions;
  const size = options?.Size?.value ?? options?.size ?? "";

  if (isMultiVariant) {
    const colour = options?.Colour?.value ?? options?.colour ?? "";
    return ` / ${size} / ${colour}`;
  }

  const comfortFeel = getComfortFeel(options);
  if (hasSingleSize(options) && comfortFeel) {
    return ` - ${size} / ${comfortFeel}`;
  }

  return ` - ${variant.title}`;
};
